<template>
  <div class="notfound">
    <div class="container">
      <img src="@/assets/images/404.png" alt="" class="notImg" />
      <div class="content">
        <div class="oops">OOPS!</div>
        <div class="page">404 PAGE NOT FOUND</div>
        <div class="tips">
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </div>
        <a href="https://www.metarace.io/" class="btn">GO TO HOMEPAGE</a>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>


<style lang="scss" scoped>
.notfound {
  width: 100%;
  height: 100%;
  .container {
    width: 900px;
    margin: 103px auto;
    display: flex;
    align-items: center;
  }

  .notImg {
    width: 385px;
    height: 475px;
  }
  .content {
    width: 515px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -28px;

    .oops {
      font-family: "Rubik-BoldItalic";
      font-size: 154px;
      color: #e4f6ff;
    }
    .page {
      font-family: "Rubik-BoldItalic";
      font-size: 46px;
    }
    .tips {
      width: 410px;
      font-family: "Rubik-Italic";
      font-size: 16px;
      text-align: center;
      margin-top: 21px;
    }
    .page,
    .tips {
      color: #242424;
    }
    .btn {
      width: 259px;
      height: 57px;
      background: #ff00f6;
      font-family: "Rubik-BoldItalic";
      font-size: 24px;
      box-shadow: 0px 8px 5px 0px rgba(226, 3, 218, 0.15);
      border-radius: 11px;
      color: #fff;
      text-align: center;
      line-height: 57px;
      margin-top: 66px;
      cursor: pointer;
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 780px) {
  .notfound {
    width: 100%;
    height: 100%;
    padding: 0 4vw;
    box-sizing: border-box;
    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20vw;
      .notImg {
        width: 51.33vw;
        height: 63.33vw;
      }
      .content {
        width: 100%;
        position: relative;
        left: 0;
        .oops {
          font-family: "Rubik-BoldItalic";
          font-size: 20.53vw;
          color: #e4f6ff;
        }
        .page,
        .tips {
          color: #242424;
        }
        .page {
          font-family: "Rubik-BoldItalic";
          font-size: 6.13vw;
        }
        .tips {
          width: 74.67vw;
          font-family: "Rubik-Italic";
          font-size: 2.8vw;
          text-align: center;
          margin-top: 2.13vw;
        }
        .btn {
          width: 34.53vw;
          height: 7.6vw;
          background: #ff00f6;
          font-family: "Rubik-BoldItalic";
          font-size: 3.2vw;
          box-shadow: 0px 1.07vw 0.67vw 0px rgba(226, 3, 218, 0.15);
          border-radius: 1.47vw;
          color: #fff;
          text-align: center;
          line-height: 7.6vw;
          margin-top: 8.8vw;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
